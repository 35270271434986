<template>
  <q-card flat class="q-pa-sm no-padding-md" style="max-width: 100vw">
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.logs_detail") }}
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="report"
        :columns="columnsI18n"
        row-key="id"
        :loading="loading"
        :filter="filter"
        :rows-per-page-options="[0]"
        binary-state-sort
        class="sticky-header-table"
      >
        <template v-slot:body-cell-bottle="props">
          <q-td :style="`background:${props.row.style};`" :props="props">
            {{ props.value }}
          </q-td>
        </template>
        <template v-slot:body-cell-cause="props">
          <q-td :style="`background:${props.row.causeStyle};`" :props="props">
            {{ props.value }}
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>
<script>
import moment from "moment";
import ReportApi from "../../apis/Report";
import { useI18n } from "vue-i18n";

export default {
  props: ["startDate", "store"],

  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      action: "list",
      showNew: false,
      showCard: false,
      filter: "",
      loading: false,
      report: [],
    };
  },
  methods: {
    async getReportDetail() {
      this.loading = true;
      try {
        console.log("start: " + typeof this.startDate);
        console.log(moment(this.startDate).format("yyyy-MM-DD"));
        const day_plus_one = moment(this.startDate).add(1, "d");
        const reportReponse = await ReportApi.getLogsDetailReport(
          moment(this.startDate).format("yyyy-MM-DD"),
          day_plus_one.format("yyyy-MM-DD"),
          this.store
        );

        this.report = reportReponse.data.sort((a, b) =>
          moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1
        );
        this.report.forEach((r) => {
          if (
            !r.bottle &&
            moment(r.created_at) < moment(this.startDate) &&
            moment(r.lastseen_at) > day_plus_one
          ) {
            r.style = "red";
          } else {
            r.style = "white";
          }
          if (r.cause == "Bottle Sensor ?") {
            r.causeStyle = "red";
          } else {
            r.causeStyle = "white";
          }
        });

        console.log(this.report);
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async viewDetail() {},
    async refresh() {
      this.showNew = false;
      this.showCard = false;
      await this.getReport();
    },

    getStatusName(state) {
      switch (state) {
        case 0:
          return "BOOT";
        case 1:
          return "READY";
        case 2:
          return "OPEN";
        case 3:
          return "TRANSACTION_OK";
        case 4:
          return "REFILL_PENDING";
        case 5:
          return "NOT_SEEN";
        default:
          return "TODO";
      }
    },
  },

  async mounted() {
    await this.getReportDetail();
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: this.$t("label.id"),
          align: "left",
          field: "id",
        },
        {
          name: "date",
          required: true,
          label: this.$t("label.date"),
          align: "left",
          field: "created_at",
        },
        {
          name: "lastseen",
          required: true,
          label: this.$t("label.lastseen"),
          align: "left",
          field: "lastseen_at",
        },
        {
          name: "locker",
          required: true,
          label: this.$t("label.locker"),
          align: "left",
          field: (row) => row.locker.numero,
        },
        {
          name: "locker_id",
          required: true,
          label: this.$t("label.id"),
          align: "left",
          field: (row) => row.locker.id,
        },
        {
          name: "bottle_before",
          required: true,
          label: this.$t("label.bottle_before"),
          align: "left",
          field: (row) =>
            row.oldState
              ? row.oldState.bottle
                ? "Present"
                : "Absent"
              : "Inconnu",
        },
        {
          name: "bottle",
          required: true,
          label: this.$t("label.bottle"),
          align: "left",
          field: (row) => (row.bottle ? "Present" : "Absent"),
        },
        {
          name: "door_before",
          required: true,
          label: this.$t("label.door_before"),
          align: "left",
          field: (row) =>
            row.oldState ? (row.oldState.door ? "Closed" : "Open") : "Inconnu",
        },
        {
          name: "door",
          required: true,
          label: this.$t("label.door"),
          align: "left",
          field: (row) => (row.door ? "Closed" : "Open"),
        },
        {
          name: "status_before",
          required: true,
          label: this.$t("label.status_before"),
          align: "left",
          field: (row) =>
            row.oldState ? this.getStatusName(row.oldState.status) : "Inconnu",
        },
        {
          name: "status",
          required: true,
          label: this.$t("label.status"),
          align: "left",
          field: (row) => this.getStatusName(row.status),
        },
        {
          name: "cause",
          required: true,
          label: this.$t("label.cause"),
          align: "left",
          field: "cause",
        },
      ];
      return columns;
    },
  },
};
</script>
