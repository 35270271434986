<template>
  <q-card flat class="q-pa-sm no-padding-md" style="max-width: 100vw">
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.refill_detail") }}
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="report"
        :columns="columnsI18n"
        row-key="id"
        :loading="loading"
        :filter="filter"
        :rows-per-page-options="[0]"
        binary-state-sort
        class="sticky-header-table"
      >
      </q-table>
    </q-card-section>
  </q-card>
</template>
<script>
import ReportApi from "../../apis/Report";
import { useI18n } from "vue-i18n";

export default {
  props: ["reportId"],

  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      action: "list",
      showNew: false,
      showCard: false,
      filter: "",
      loading: false,
      report: [],
    };
  },
  methods: {
    async getReportDetail() {
      this.loading = true;
      try {
        const reportReponse = await ReportApi.getRefillDetailReport(
          this.reportId
        );

        this.report = reportReponse.data.data.sort(
          (a, b) =>
            a.name.substring(0, 1).localeCompare(b.name.substring(0, 1)) ||
            a.name.substring(1) - b.name.substring(1)
        );
        console.log(this.report);
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async viewDetail() {},
    async refresh() {
      this.showNew = false;
      this.showCard = false;
      await this.getReport();
    },

    getStatusName(state) {
      switch (state) {
        case 0:
          return "BOOT";
        case 1:
          return "READY";
        case 2:
          return "OPEN";
        case 3:
          return "TRANSACTION_OK";
        case 4:
          return "REFILL_PENDING";
        case 5:
          return "NOT_SEEN";
        default:
          return "TODO";
      }
    },
  },

  async mounted() {
    await this.getReportDetail();
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: this.$t("label.id"),
          align: "left",
          field: "id",
        },
        {
          name: "name",
          required: true,
          label: this.$t("label.numero"),
          align: "left",
          field: "name",
        },
        {
          name: "stateBefore",
          required: true,
          label: this.$t("label.stateBefore"),
          align: "left",
          field: (row) => this.getStatusName(row.stateBefore),
        },
        {
          name: "stateAfter",
          required: true,
          label: this.$t("label.stateAfter"),
          align: "left",
          field: (row) => this.getStatusName(row.stateAfter),
        },
      ];
      return columns;
    },
  },
};
</script>
